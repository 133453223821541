import { useRouteError } from "react-router-dom";

import noq from "../assets/noq-logo.svg";

export default function Error() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      className="container mx-auto flex flex-col items-center my-10 h-full px-4"
      id="error-page"
    >
      <img src={noq} alt="noq events logo" className="w-1/4" />
      <h1 className="mt-8">Oops! Something went wrong.</h1>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
