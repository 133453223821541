import React from "react";

import ig from "../assets/ig.svg";
import fb from "../assets/fb.svg";
import mail from "../assets/mail.svg";
import whatsapp from "../assets/whatsapp.svg";

export default function Socials() {
  return (
    <div className="mt-8 text-center">
      <section>
        <p className="font-medium">
          Welcome to noq.events,{" "}
          <span className="font-normal text-sm italic">
            (pronounced '/nɒk/')
          </span>
          <br />
          your trusted partner in event planning and management.
        </p>
        <p></p>
        <br />

        <p>
          We are a team of dedicated and passionate professionals committed to
          making your event come to life.
        </p>
        <br />
        <p>
          Whether it's a wedding, corporate event, or any other special
          occasion, we work tirelessly to ensure that every detail is executed
          flawlessly.
        </p>
      </section>
      <section>
        <h2 className="text-sm font-medium mt-14 lg:text-lg text-title text-slate-400">
          Get in touch with us and
          <br />
          let's plan your next event together!
        </h2>
        <div className="mt-4">
          <div className="mt-2">
            <img
              src={ig}
              alt="instagram"
              width={16}
              height={16}
              className="inline mr-2"
            />
            <a className="text-sm" href="https://www.instagram.com/noq.events/">
              @noq.events
            </a>
          </div>

          <div className="mt-2">
            <img
              src={mail}
              alt="email"
              width={16}
              height={16}
              className="inline mr-2"
            />
            <a className="text-sm" href="mailto:hello@noqevents.live">
              hello@noqevents.live
            </a>
          </div>

          <div className="mt-2">
            <img
              src={fb}
              alt="email"
              width={16}
              height={16}
              className="inline mr-2"
            />
            <a className="text-sm" href="https://www.facebook.com/noqevents.sg">
              facebook
            </a>
          </div>

          <div className="mt-2">
            <img
              src={whatsapp}
              alt="email"
              width={16}
              height={16}
              className="inline mr-2"
            />
            <a className="text-sm" href="https://wa.me/+6597290552">
              whatsapp
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
