import React from "react";

import noq from "../assets/noqog.png";
import Socials from "../components/socials";

export default function Index() {
  return (
    <div className="container mx-auto flex flex-col items-center mt-8 font-light h-full px-4 w-5/6 lg:w-1/2">
      <img src={noq} alt="noq events logo" className="w-full" />
      <h1 className="text-xl lg:text-2xl text-slate-400 text-center font-semibold text-title">
        We're upgrading our site to serve you better.
      </h1>
      <Socials />
    </div>
  );
}
